import { html, nothing } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map.js';
import { map } from 'lit-html/directives/map.js';
import { range } from 'lit-html/directives/range.js';
import { ref } from 'lit-html/directives/ref.js';
import { repeat } from 'lit-html/directives/repeat.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { when } from 'lit-html/directives/when.js';
import {ifDefined} from 'lit/directives/if-defined.js';
import { resultPlaceholderTemplate, resultTemplate, selectPrices } from './result';
import { Pagination } from '../../util/pagination';
import ListingURL from './url';
import { facetPersonalisable, facetPersonalisableConstructorValue, facetFlashSale, facetFlashSaleConstructorValue, findMappedFacet, groupFacets, isFacetOptionSelected, isOptionSelectable } from './facets';
import { arrayToClassMap } from '../../util/classmap';
import { isBreakpointMatch } from '../../util/viewport';
import { pushGAEvent } from '../tracking';
import { getCurrency, getPriceCatalogue, convertPriceForReporting } from '../../util/currency';
import { trackProductClick } from '../tracking/list';
import '../../components/Slider';
import { getEventId } from '../../util/offers';

export const buildModifiedURL = (modify) => {
  const url = ListingURL.parse(new URL(window.location.href));
  modify(url);
  return url.build();
};

/**
 * Convert a single result from constructor to a format for the datalayer
 *
 * Requires the full results object to get data from the facet
 */
const convertConstructorItemToDatalayer = (result, item, position) => {
  const itemIsSet = item.data.id.startsWith('G');

  const typeFacet = result.response.facets.find((facet) => facet.name == "type");
  const currency = getCurrency();
  const priceCatalogue = getPriceCatalogue();
  const localCat = item.data?.facets?.find((f) => f.name == "type")?.values[0]
  const category = itemIsSet
    ? "Gift Sets"
    : typeFacet.options.find((option) => option.value == localCat)?.display_nameupsellblock

  const eventId = getEventId();
  const prices = selectPrices({ data: item.data, currency, priceCatalogue, eventId });
  const price = eventId ? prices.now : (prices.was ?? prices.now);
  const gbpPrice = Math.round((+convertPriceForReporting(price) + Number.EPSILON) * 100) / 100;

  const dl = {
    brand: "Monica Vinader",
    name: item.value,
    id: item.data.code,
    price,
    gbpPrice,
    discount: prices.discount ?? prices.bundleDiscount ?? 0,
    variant: "",

    category,
    list: window.mv?.plp?.canonicalListingUrl ?? '/shop',
    position,
  };

  // only giftsets have this attribute
  if ('is_bundle' in item.data) {
    dl.isBundle = item.data.is_bundle;
  }

  return dl;
};

/**
 * Convert a page of results from constructor to a format we can push to the datalayer
 * We need the full result object because we use the facet options to get the nicely formatted name for categories as well
 * as position
 */
export const convertConstructorResultToDatalayer = (result) => {
  let position = result.request.offset;
  return result.response.results.map((item) => convertConstructorItemToDatalayer(result, item, position++));
};

const onFacetOptionChange = (navigate) => (e) => {
  e.preventDefault();
  e.stopPropagation();

  const input = e.target;

  navigate(buildModifiedURL((url) => {
    url.setPage(1);
    url.toggleFacetValue(input.name, input.value, input.checked);
  }));
}

// We keep a reference to the facet content elements, so that we can copy the
// style attribute if the element is replaced. This prevents an issue where the
// option panel shifts horizontally before some JavaScript has run to set the
// css left value.
const facetContentRefs = {};
const headerFacetTemplate = ({
  facet,
  navigate,
  toggleFacetVisibility,
  optionsVisible,
  isTabletOrSmaller,
  alwaysVisible,
  locale,
  currency,
}) => {
  const { name, type, display_name, options, status, min, max } = facet;

  const validOptions = options ? options.filter(isOptionSelectable) : [];
  const selectedOptions = options ? options.filter((option) => option.status === 'selected') : [];

  const facetConfig = findMappedFacet(facet.name);
  const { filterContentClasses, filterGroupClasses, filterGroupClassesMobile, optionDisplayMode } = facetConfig;

  return html`
    <article
      class=${classMap({
        'filter': true,
        'filter--constructor': true,
        'filter--active': optionsVisible,
      })}
      data-name="${name}"
    >
      <button
        type="button"
        class="filter__button js-gtm-element"
        data-gatype="filter_or_sort_click"
        data-galabel="${display_name} |"
        @click=${(e) => {
          e.preventDefault();
          e.stopPropagation();

          toggleFacetVisibility({ facet: name });
        }}
      >
        <span class="filter__button-content">
          <span class="filter__name">
            ${display_name}

            ${when(selectedOptions.length > 0, () => html`
              <span class="filter__count">
                ${selectedOptions.length}
              </span>
            `)}
            ${when(
                selectedOptions.length === 0 && 
                status !== undefined &&
                Object.keys(status).length > 0
            , () => html`
              <span class="filter__count">
                  1
              </span>
            `)}
          </span>

          <img width="9" height="7" src="/images/2020/dropdown.svg" class="${!alwaysVisible ? 'filter__icon filter__icon--constant' : nothing}" alt="Open ${display_name} filter" fetchPriority="low">

          ${when(!alwaysVisible, () => html`
            <img src="/images/2020/plus-black.svg" class="filter__icon filter__icon--inactive" alt="Open ${display_name} filter" fetchPriority="low">
            <img src="/images/2020/minus.svg" class="filter__icon filter__icon--active" alt="Close ${display_name} filter" fetchPriority="low">
          `)}
        </span>
      </button>
      <div
        ${ref((el) => {
          if (!isTabletOrSmaller) {
            facetContentRefs[facet.name] = el;
          }
        })}
        class=${classMap({
          'filter__content': true,
          ...filterContentClasses,
        })}"
      >
        <div class=${classMap({
          'filter-group': true,
          'filter-group--constructor': true,
          ...(isTabletOrSmaller ? (filterGroupClassesMobile ?? filterGroupClasses) : filterGroupClasses),
        })}>
          ${when(type === 'multiple', () => html`
            <div class="filter-group__actions">
              ${when(
                selectedOptions.length === 0,
                () => html`
                  <button
                    type="button"
                    class="filter-group__button filter-group__button--select-all js-gtm-element"
                    data-gatype="filter_or_sort_click"
                    data-galabel="${display_name} | Select all"
                    @click=${(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      navigate(buildModifiedURL((url) => {
                        url.setPage(1);
                        url.setFacetValues(name, validOptions.map(({ value }) => value));
                      }));
                    }}
                  >
                    <span class="filter-group__button-content">Select all</span>
                  </button>
                `,
                () => html`
                  <button
                    type="button"
                    class="filter-group__button filter-group__button--clear js-gtm-element"
                    data-gatype="filter_or_sort_click"
                    data-galabel="${display_name} | Clear"
                    style="display: inline-block"
                    @click=${(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      navigate(buildModifiedURL((url) => {
                        url.setPage(1);
                        url.setFacetValues(name, undefined);
                      }));
                    }}
                  >
                    <span class="filter-group__button-content">
                      Clear
                        <img src="/images/2020/cross.svg" fetchPriority="low">
                    </span>
                  </button>
                `,
              )}
            </div>
          `)}
          ${when(type === 'multiple', () => html`
            <div class="filter-group__fields filter-group__fields--constructor">
            ${repeat(validOptions, (option) => option.value, (option) => html`
              <label class="filter-group__field filter-group__field--constructor">
                <div class=${classMap({
                  'check-replace': true,
                  'check-replace--active': option.status === 'selected',
                })}>
                  <input
                    type="checkbox"
                    name="${facet.name}"
                    value="${option.value}"
                    ?checked=${option.status === 'selected'}
                    autocomplete="false"
                    class="js-gtm-element"
                    check-replaced="true"
                    style="display: none;"
                    data-gatype="filter_or_sort_click"
                    data-galabel="${facet.display_name} | ${option.display_name}"
                    @change=${onFacetOptionChange(navigate)}
                  >
                  <img src="/images/2020/tick.svg" alt="${option.status === 'selected' ? 'Checked' : 'Unchecked'}" fetchPriority="low">
                </div>

                ${when(option.data?.image, () => html`
                  <span class=${classMap({
                    'swatch': true,
                    'swatch--active': option.status === 'selected' && optionDisplayMode === 'box',
                    'swatch--readonly': optionDisplayMode === 'swatch',
                    'swatch--box': optionDisplayMode === 'box',
                    'swatch--white': option.value === 'white',
                  })}>
                    <span class="swatch__display">
                      <img src="${option.data.image}" alt="" loading="lazy">
                    </span>
                  </span>
                `)}
                ${when(option.data?.swatch_class?.length && !option.data?.image, () => html`
                  <span class="swatch swatch--readonly">
                    <span class="swatch__display">
                      <i class=${classMap(arrayToClassMap(option.data.swatch_class))}></i>
                    </span>
                  </span>
                `)}

                <span class="filter-group__name">${option.display_name}</span>
              </label>
            `)}
            </div>
          `)}
          ${when(type === 'range', () => {
            const sliderMin = Math.max(min, facetConfig.sliderMin ?? min);
            const sliderMax = Math.min(max, facetConfig.sliderMax ?? max);

            return html`
              <div class="filter-group__range filter-group__range--constructor">
                <mv-slider
                  min="${sliderMin}"
                  max="${sliderMax}"
                  valuePosition="above-handles"
                  lower="${status.min ?? sliderMin}"
                  upper="${status.max ?? sliderMax}"
                  margin="5"
                  connect="true"
                  @change="${(e) => {
                    const values = e.detail.values;
                    let selectedMin = parseInt(values[0], 10);
                    let selectedMax = parseInt(values[values.length - 1], 10)

                    if (selectedMax === sliderMax) {
                      selectedMax = max;
                    }

                    navigate(buildModifiedURL((url) => {
                      url.setPage(1);
                      url.setFacetValues(facet.name, [`${selectedMin}-${selectedMax}`]);
                    }));
                  }}"
                  .formatValue="${ifDefined(facetConfig.formatValue)}"
                ></mv-slider>
              </div>
            `
          })}
        </div>
      </div>
    </article>
  `;
};

const personalisationFacetTemplate = ({ facet, classes = {}, navigate }) => html`
  <article class=${classMap({
    'filter': true,
    'filter--switch': true,
    'filter--disabled': facet.options.length <= 1,
    ...classes,
  })}>
    <div class="switch">
      <label class="switch__label">
        <img src="/images/2020/personalisation-black.svg" width="14px" height="16px" alt="" fetchPriority="low">
        <span class="switch__text">Only show products that can be personalised</span>
        <input
          type="checkbox"
          name="${facet.name}"
          value="${facetPersonalisableConstructorValue}"
          class="switch__input"
          autocomplete="false"
          ignore
          ?checked=${facet.options.find(({ value }) => value === facetPersonalisableConstructorValue)?.status === 'selected'}
          @change=${onFacetOptionChange(navigate)}
        >
        <span class="switch__display"></span>
      </label>
    </div>
  </article>
`;
const flashSaleFacetTemplate = ({ facet, classes = {}, navigate }) => html`
  <article class=${classMap({
    'filter': true,
    'filter--switch': true,
    'filter--disabled': facet.options.length <= 1,
    ...classes,
  })}>
    <div class="switch">
      <label class="switch__label">
        <span class="switch__text">${facet.display_name}</span>
        <input
          type="checkbox"
          name="${facet.name}"
          value="${facetFlashSaleConstructorValue}"
          class="switch__input"
          autocomplete="false"
          ignore
          ?checked=${facet.options.find(({ value }) => value === facetFlashSaleConstructorValue)?.status === 'selected'}
          @change=${onFacetOptionChange(navigate)}
        >
        <span class="switch__display"></span>
      </label>
    </div>
  </article>
`;

const clearAllButtonTemplate = ({ facetState, navigate, filterClasses = {} }) => html`
  <article
    class=${classMap({
      'filter': true,
      'filter--clear': true,
      ...filterClasses,
    })}
  >
    <button
      type="button"
      class="filter__button js-gtm-element"
      data-gatype="filter_or_sort_click"
      data-galabel="Clear all |"
      @click=${(e) => {
        e.preventDefault();
        e.stopPropagation();

        navigate(buildModifiedURL((url) => {
          url.setPage(1);

          url.facets
            .map(({ name }) => name)
            .filter((name) => name !== facetPersonalisable && facetState[name]?.fixed !== true)
            .forEach((name) => url.setFacetValues(name, undefined))
          ;
        }));
      }}
    >
      <span class="filter__button-content">
        <span class="filter__name">Clear all</span>
      </span>
    </button>
  </article>
`;

/**
 * Return a lit-html template that renders the shared part of a listing page.
 *
 * This is used for PLPs and the search results page.
 *
 * @param {*} param0
 * @returns {TemplateResult}
 */
export const listingTemplate = ({
  mode,
  locale,
  currency,
  eventId,
  result,
  loading,
  selectedVariations,
  navigate,
  setSelectedVariation,
  facetState,
  showAllFacets,
  toggleFacetVisibility,
  toggleShowAllFacets,
  sort,
  toggleSortVisibility,
  callouts = [],
  maxResultsPerPage,
  pageAdjustments,
  isTabletOrSmaller,
}) => {
  const response = result?.response;
  const results = response?.results ?? [];

  const {
    browse_filter_name: browseFilterName,
    browse_filter_value: browseFilterValue,
    num_results_per_page: resultsPerPage = maxResultsPerPage,
    offset = 0,
  } = result?.request ?? {};

  const totalResultCount = response?.total_num_results ?? 0;

  const numColumns = 4;

  // Render all the result cards.
  const cards = results.map((result, i) => resultTemplate({
    result,
    locale,
    currency,
    eventId,
    selectedVariation: selectedVariations?.[result.data.variation_id] ?? result.data.variation_id,
    setSelectedVariation,
    position: offset + i,
    lazy: i > 4,
    onClick: (e) => {
      e.preventDefault();

      trackProductClick({
        uri: e.target.closest('a').href,
        list: window.mv?.plp?.canonicalListingUrl ?? '/shop',
        product: convertConstructorItemToDatalayer({ response }, result, offset + i),
      });
    },
  }));

  const sortedCallouts = [...callouts].sort((a, b) => a.position - b.position);

  // Calculate callout position adjustments.
  let adjustments = [];
  for (const callout of sortedCallouts) {
    const { width, height, position } = callout;

    const relPosition = position - offset;
    const x1 = relPosition % numColumns;
    const y1 = Math.floor(relPosition / numColumns);

    let first = true;
    for (let y = y1; y < y1 + height; y++) {
      for (let x = x1; x < x1 + width; x++) {
        adjustments[y * numColumns + x] = first ? 0 : -1;
        first = false;
      }
    }
  }

  // Render callouts in the correct positions.
  for (const callout of sortedCallouts) {
    const { position } = callout;
    // Ignore callouts that are not on the current page. Also ignore the last
    // card callout, which is handled later on.
    if (!Number.isInteger(position) || position < offset || position >= offset + maxResultsPerPage) {
      continue;
    }

    const relPosition = position - offset;

    const idx = relPosition + adjustments.slice(0, relPosition).reduce((a, b) => a + b, 0);

    cards.splice(idx, 0, html`${unsafeHTML(callout.html)}`);
  }

  // Render and add the last card callout if we're on the last page of results
  // and there's space for it.
  if (cards.length > 0 && cards.length < maxResultsPerPage && offset + resultsPerPage >= totalResultCount) {
    const callout = sortedCallouts.find(({ position }) => position === 'lc');
    if (callout) {
      cards.push(html`${unsafeHTML(callout.html)}`);
    }
  }

  const pagination = new Pagination(maxResultsPerPage);
  pagination.setOffset(offset);
  pagination.setItemCount(totalResultCount);
  pagination.setAdjustments(pageAdjustments);
  const paginationLinks = pagination.links;

  const onSortHeaderClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    toggleSortVisibility();
    if (!sort.open) {
      document.body.addEventListener('click', () => toggleSortVisibility({ open: false }), { once: true });
    }
  };

  const { headerFacets, numHeaderFacetsVisible, personalisationFacet, flashSaleFacet } = groupFacets({
    result,
    facetState,
    showAllFacets,
    isTabletOrSmaller,
  });

  const hasFacets = headerFacets.length || personalisationFacet || flashSaleFacet;

  return html`
    <div class="wrap">
      <div class="product-catalogue__filter-wrap">
        <div class="product-catalogue__top">
        ${when(hasFacets || loading, () => html`
          <div class="product-catalog__filters filters">
            ${when(loading && !headerFacets.length, () => html`
              <div class="filter filter--placeholder"></div>
            `)}

            ${map(headerFacets.slice(0, numHeaderFacetsVisible), (facet) => headerFacetTemplate({
              facet,
              navigate,
              toggleFacetVisibility,
              optionsVisible: facetState[facet.name]?.open ?? false,
              alwaysVisible: true,
              locale,
              currency,
            }))}

            ${when(headerFacets.length > numHeaderFacetsVisible, () => html`
              <article class="filter filter--more">
                  <button
                    type="button"
                    class="filter__button js-gtm-element"
                    data-gatype="filter_or_sort_click"
                    data-galabel="More |"
                    @click=${(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      toggleShowAllFacets({ show: true });
                    }}
                  >
                    <span class="filter__button-content">
                      <span class="filter__name">
                        More
                        <img src="/images/2020/plus-black.svg" width="14px" height="16px" alt="" fetchPriority="low">
                      </span>
                    </span>
                  </button>
              </article>
            `)}

            ${when(headerFacets.some(isFacetOptionSelected), () => clearAllButtonTemplate({
              facetState,
              navigate,
              filterClasses: {
                'm-false': true,
              },
            }))}

              ${when(flashSaleFacet, () => flashSaleFacetTemplate({
                  facet: flashSaleFacet,
                  navigate,
                  classes: {
                      'm-false': true,
                  },
              }))}

            ${when(personalisationFacet, () => personalisationFacetTemplate({
              facet: personalisationFacet,
              navigate,
              classes: {
                'm-false': true,
              },
            }))}
          </div>
        `)}

          <div class="product-catalogue__sorter">
            <article class="filter filter--pagination">
              <span class="filter__name">
                ${results.length}/${totalResultCount} products
              </span>
            </article>

            ${when(response?.sort_options?.length, () => html`
              <div
                class=${classMap({
                  'filter': true,
                  'filter--constructor': true,
                  'filter--sort': true,
                  'filter--active': sort.open,
                })}
                @click=${(e) => onSortHeaderClick(e)}
              >
                <button
                  type="button"
                  class="filter__button js-filter__button js-gtm-element"
                  data-gatype="filter_or_sort_click"
                  data-galabel="Sort by |"
                >
                  <span class="filter__button-content">
                    <span class="filter__name">Sort by</span>
                    <img width="9" height="7" src="/images/2020/dropdown.svg" alt="" fetchPriority="low">
                  </span>
                </button>
                <div class="filter__content">
                  <div class="filter-group filter-group--a">
                    <div class="filter-group__fields">
                      <ul class="sorter-list">
                        ${map(response.sort_options, ({ sort_by, sort_order, display_name, status }) => html`
                          <li
                            class=${classMap({
                              'filter-group__field': true,
                              'filter-group__field--constructor': true,
                              'sorter-list__item': true,
                              'sorter-list__item--active': status === 'selected',
                            })}
                          >
                            <a
                              class="js-gtm-element"
                              data-gatype="filter_or_sort_click"
                              data-galabel="Sort by | ${display_name}"
                              href="${buildModifiedURL((url) => {
                                url.setPage(1);
                                url.setSort(sort_by, sort_order);
                              })}"
                              @click=${(e) => {
                                e.preventDefault();
                                navigate(e.target.href)
                              }}
                            >
                              ${display_name}
                            </a>
                          </li>
                        `)}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            `)}
          </div>
        </div>
      </div>

      <div class="product-catalogue">
        ${when(loading || cards.length > 0, () => html`
        <div
          class="product-catalogue-wrap product-catalogue-wrap--grid js-gtm-context"
          data-gacontext="plp"
          elementtiming="plplisting"
          ?data-cnstrc-search=${mode === 'search'}
          ?data-cnstrc-browse=${mode !== 'search'}
          data-cnstrc-num-results="${totalResultCount}"
          data-cnstrc-filter-name=${browseFilterName ?? nothing}
          data-cnstrc-filter-value=${browseFilterValue ?? nothing}
        >
          ${when(
            loading,
            () => html`${map(range(resultsPerPage), resultPlaceholderTemplate)}`,
            () => html`${cards}`,
          )}
        </div>

        <div class="product-catalogue__pagination">
            ${when(paginationLinks.length, () => html`
            <nav class="pagination pagination--right-align">
              <div class="pagination__wrap">
                <div class="pagination__links">
                  ${map(paginationLinks, ({ label, page, active, cta, disabled }) => html`
                    <a
                      class=${classMap({
                        'pagination__link': true,
                        'pagination__link--active': active,
                        'cta': cta,
                        'cta--disabled': cta && disabled,
                      })}
                      href="${buildModifiedURL((url) => { url.setPage(page); })}"
                      @click=${(e) => {
                        e.preventDefault();
                        navigate(e.target.href);
                      }}
                    >
                      ${label}
                    </a>
                  `)}
              </div>
            </nav>
            `)}
        </div>

        `, () => html`
          <div class="listing-banner__content listing-banner__content--padded">
            <h1 class="product-listing__no-results">
              No products found, please reduce the number of filters or clear all
            </h1>
          </div
        `)}
      </div>
    </div>
  `;
}

const signUpCalloutObserver = new IntersectionObserver(
  (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const element = entry.target;
        observer.unobserve(element);

        pushGAEvent(element.dataset.signupCategory, element.dataset.viewAction, element.dataset.signupLabel);
      }
    });
  },
  {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  },
);

export const onListingRendered = ({ container, scrollToTop }) => {
  window.requestAnimationFrame(() => {
    const isTabletOrSmaller = isBreakpointMatch('tablet');

    const containerBounds = container.getBoundingClientRect();

    for (const content of container.querySelectorAll('.filter .filter__content--full-width')) {
      let contentStyleLeft;
      if (!isTabletOrSmaller) {
        contentStyleLeft = `${0 - content.parentElement.offsetLeft}px`;
      }

      content.style.left = contentStyleLeft;
    }

    const catalogueTop = container.querySelector('.product-catalogue__top');
    for (const facet of container.querySelectorAll('.filter.filter--active')) {
      const facetConfig = findMappedFacet(facet.dataset.name);
      const content = facet.querySelector('.filter__content');

      if (
        !isTabletOrSmaller
        && !content.classList.contains('.filter__content--full-width')
        && content.querySelector([
          '.filter-group--a',
          '.filter-group--b',
          '.filter-group--s',
        ].join(', '))
      ) {
        const filterPos = facet.getBoundingClientRect();
        const contentPos = content.getBoundingClientRect();
        const catalogueTopPos = catalogueTop.getBoundingClientRect();

        if (
          contentPos.x !== filterPos.x
          && (
            contentPos.right < filterPos.right
            || contentPos.x > filterPos.x
          )
        ) {
          content.style.left = null;
        }

        if (contentPos.right > catalogueTopPos.right + 1) {
          content.style.left = `${catalogueTopPos.right - contentPos.right}px`;
        }
      }

      for (const slider of content.querySelectorAll('mv-slider')) {
        slider.adjustValuePositions();
      }
    }

    if (scrollToTop) {
      // Wait for 2 animation frames, to ensure that the page actually scrolls.
      window.requestAnimationFrame(() => {
        window.requestAnimationFrame(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        });
      });
    }

    // Initialise sign up callouts.
    container.querySelectorAll('.js-callout-signup-container').forEach((container) => {
      const signupSteps = new SignupSteps(container);
      signupSteps.init();
      signUpCalloutObserver.observe(container);
    });
  });
};

export const facetDialogTemplate = ({
  result,
  facetState,
  navigate,
  toggleFacetVisibility,
  toggleShowAllFacets,
  totalResultCount,
}) => {
  const isTabletOrSmaller = true;

  const { headerFacets, personalisationFacet, flashSaleFacet } = groupFacets({
    result,
    facetState,
    showAllFacets: true,
    isTabletOrSmaller,
  });

  return html`
    <div class="filters__group">
      <div class="filters__banner">
        Filters
        <button
          type="button"
          class="filters__close"
          @click=${(e) => {
            e.preventDefault();
            e.stopPropagation();

            toggleShowAllFacets({ show: false });
          }}
        >
          <img src="/images/2020/cross.svg" alt="Close filters" fetchPriority="low">
        </button>
      </div>

      ${when(personalisationFacet, () => html`
        <div class="filters__personalisation">
          ${personalisationFacetTemplate({
            facet: personalisationFacet,
            navigate,
          })}
        </div>
      `)}

        ${when(flashSaleFacet, () => html`
        <div class="filters__personalisation">
          ${flashSaleFacetTemplate({
            facet: flashSaleFacet,
            navigate,
        })}
        </div>
      `)}

        ${map(headerFacets, (facet) => headerFacetTemplate({
        facet,
        navigate,
        toggleFacetVisibility,
        optionsVisible: facetState[facet.name]?.open ?? false,
        isTabletOrSmaller,
      }))}


      ${when(headerFacets.some(isFacetOptionSelected), () => clearAllButtonTemplate({
        facetState,
        navigate,
      }))}

      <button
        type="button"
        class="button button--alt"
        @click=${(e) => {
          e.preventDefault();
          e.stopPropagation();

          toggleShowAllFacets({ show: false });
        }}
      >
        <span>
          View ${totalResultCount} results
          <img src="/images/2020/arrow-right-white.svg" width="14px" height="16px" alt="" fetchPriority="low">
        </span>
      </button>
    </div>
  `;
};

export const onFacetDialogRendered = ({ dialog, open }) => {
  for (const slider of dialog.querySelectorAll('mv-slider')) {
    slider.adjustValuePositions();
  }

  if (open && !dialog.open) {
    dialog.showModal();
  } else if (!open && dialog.open) {
    dialog.close();
  }
};
