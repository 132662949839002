// This file contains code related to list attribution tracking.

import { trackNavigation } from '.';
import { getCurrency } from '../../util/currency';
import { d3rLocalStorage } from '../../util/storage';
import { dataLayerPush } from '.';

const productListSessionKey = 'productListSession';

export const ensureProductListSessionIdSet = () => {
  if (null === sessionStorage.getItem(productListSessionKey)) {
    const sessionId = '_' + Math.random().toString(36).substring(2, 11);
    sessionStorage.setItem(productListSessionKey, sessionId);
  }
};

export const getProductListSessionId = () => sessionStorage.getItem(productListSessionKey);

export const trackProductListImpressions = ({ list, items, currency }) => {
  items.forEach((item) => {
    let getList = (item.list || list).replace(new RegExp('^' + window.localePathPrefix + '/'), '/');
    item.list = getList.split('?')[0];
  });

  dataLayerPush({
    event: 'productImpression',
    ecommerce: {
      currencyCode: currency ?? getCurrency(),
      impressions: items,
    },
  });
};

export const getProductElementData = (el, overrides = {}) => {
  const a = el.closest('.js-gtm-element');

  const data = {
    id: a.dataset.gaid,
    name: a.dataset.galabel,
    category: a.dataset.category,
    brand: a.dataset.brand,
    position: parseInt(a.dataset.position, 10),
  };

  if (a.dataset.price) {
    data.price = parseFloat(a.dataset.price);
  }

  if (a.dataset.gbpPrice) {
      data.gbpPrice = parseFloat(a.dataset.gbpPrice);
  }

  if (a.dataset.isBundle) {
    data.isBundle = ['true', '1'].includes(a.dataset.isBundle);
  }

  if (a.dataset.discount) {
    data.discount = parseFloat(a.dataset.discount);
  }

  return Object.assign(data, overrides);
};

/**
 * Track a click on a product element.
 *
 * @param {PointerEvent} e
 * @param {string} list
 * @returns {void}
 */
export const trackProductElementClick = (e, list, overrides = {}) => {
  e.preventDefault();

  let target = e.target;
  let el = target.closest('.js-gtm-element');

  if (target.closest('.js-quick-atb')) {
    setupQuickadd(e, list, overrides);
    MV.quickaddClick(e);

    return;
  }

  if ('element' in overrides) {
    target = overrides.element;
    overrides = Object.assign({}, overrides);
    delete overrides.element;
  }

  el = target.closest('.js-gtm-element');

  const product = getProductElementData(el, overrides);

  trackProductClick({
    uri: e.target.closest('a')?.href,
    list,
    product,
  });
};

export const setupQuickadd = (e, list, overrides) => {
    let el = e.target.closest('.js-gtm-element');
    // cover when the qatb button is a sibling of the gtm element
    // i.e. basket rec engine + upsell
    el ??= e.target.parentElement.querySelector('.js-gtm-element');

    const product = getProductElementData(el, overrides);

    MV.tracking.update(product.id, list, product.position, window.location.pathname);
};

export const trackProductClick = ({ uri, list, product }) => {

  MV.tracking.update(product.id, list, product.position, window.location.pathname);

  const eventData = {
    event: 'eec.productClick',
    ecommerce: {
      click: {
        actionField: { list },
        products: [product],
      },
    },
  };

  if (uri) {
    trackNavigation(uri, eventData);
  } else {
    dataLayerPush(eventData);
  }
};

/**
 * Gets the tracking data for a given product if it has been impressed upon the
 * user.
 *
 * @param {string} product - gaid
 * @returns {object|boolean}
 */
export const trackingFor = (product) => {
    if (!d3rLocalStorage?.isAvailable()) {
        return false;
    }

    if (!d3rLocalStorage.exists('productStore', 'tracking')) {
        return false;
    }

    const productStore = JSON.parse(d3rLocalStorage.get('productStore', 'tracking'));

    for (const [storedProduct, data] of Object.entries(productStore)) {
        if (storedProduct === product) {
            return data;
        }
    }
}

/**
 * Stores or updates tracking data for a given product.
 *
 * @param {string} product - gaid
 * @param {string} list
 * @param {int} position
 * @param {string} url
 * @param {string} canonicalUrl
 *
 * @returns {void}
 */
export const updateTrackingFor = (product, list, position, url) => {

    let canonicalUrl;

    ({ list, url, canonicalUrl } = validateTracking({ list, url }));

    // if any paramters are missing, return early
    if (!product || !list || typeof position !== 'number' || !url) {
        console.warn('Missing parameters for MV.tracking.update, was passed:', { product, list, position, url, canonicalUrl } );
        return;
    }

    if (!d3rLocalStorage?.isAvailable()) {
        return;
    }

    let productStore = {};

    if (d3rLocalStorage.exists('productStore', 'tracking')) {
        productStore = JSON.parse(d3rLocalStorage.get('productStore', 'tracking'));
    }

    productStore[product] = {
        id: product,
        list,
        position,
        url: window.location.pathname,
        canonicalUrl,
        updated: new Date().toISOString(),
    };

    let products = Object.values(productStore)
        .sort((a, b) => a.updated - b.updated) // sort by updated date
        .slice(-100) // keep only the last 100
        .map(p => [p.id, p]); // convert back to key value pairs

    // rebuild the object
    productStore = Object.fromEntries(products);

    d3rLocalStorage.set('productStore', JSON.stringify(productStore), 'tracking');
}

const validateTracking = ({ list, url, canonicalUrl }) => {

    if ( typeof list !== 'string' || !url) {
        console.warn('Missing parameters for MV.tracking.update, was passed:', { list, url, canonicalUrl } );
        return;
    }

    // if url inclludes host, strip it
    url = url.replace(window.location.origin, '');

    // if no explicit canonical url
    canonicalUrl ??= url.replace(new RegExp('^' + window.localePathPrefix + '/'), '/');

    return { list, url, canonicalUrl };
}

const convertListingsData = async () => {
    if (!d3rLocalStorage?.isAvailable()) {
        return;
    }

    console.log('starting conversion of plp.listingsData');

    const listingsArray = JSON.parse(d3rLocalStorage.get('listingsData', 'plp'));
    listingsArray.reverse();

    let formattedProducts = {};

    console.log(formattedProducts);

    listingsArray.forEach((data) => {
        if (data.items) {
            data.items.forEach((item) => {
                formattedProducts[item.id] = {
                    id: item.id,
                    list: data.listUrl,
                    position: item.position,
                    url: data.listUrl,
                    updated: new Date().toISOString(),
                };
            });
        }
    });

    // leave last 100 array items
    formattedProducts = Object.values(formattedProducts)
        .sort((a, b) => a.updated - b.updated) // sort by updated date
        .slice(-100) // keep only the last 100
        .map(p => [p.id, p]); // convert back to key value pairs

    // rebuild the object
    formattedProducts = Object.fromEntries(formattedProducts);

    d3rLocalStorage.set('productStore', JSON.stringify(formattedProducts), 'tracking');
    d3rLocalStorage.delete('listingsData', 'plp');
}

MV.tracking = MV.tracking || {};
MV.tracking.for = trackingFor;
MV.tracking.update = updateTrackingFor;
MV.tracking.productClick = trackProductClick;
MV.tracking.productElementClick = trackProductElementClick;
MV.tracking.ensureProductListSessionIdSet = ensureProductListSessionIdSet;
MV.tracking.setupQuickadd = setupQuickadd;
MV.tracking.convertListingsData = convertListingsData;
MV.tracking.impressProducts = trackProductListImpressions;
MV.tracking.getProductElementData = getProductElementData;
